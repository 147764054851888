import { useState, KeyboardEvent } from 'react';

type ChatInputComponentProps = {
    onSubmit: (input: string) => void;
}

const ChatInputComponent: React.FC<ChatInputComponentProps> = ({ onSubmit }) => {
    var [input, setInput] = useState<string>('');

    const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            submit();
        }
    }

    const submit = () => {
        if (input.trim() === '') return;

        onSubmit(input);
        setInput('');
    }

    return (
        <footer className="absolute sticky inset-x-0 bottom-0 z-50 bg-stone-100 p-4 sm:px-6 lg:px-8 flex flex-row">
            <textarea
                name="chat-input"
                data-testid="chat-input"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={onKeyDown}
                autoFocus={true}
                className="block w-full h-full resize-none rounded-md border border-slate-400 focus:outline-none py-1.5 pl-4 text-gray-900 sm:text-sm sm:leading-6 pr-[50px]"
            />
            <button onClick={submit} className="text-rust-500 hover:text-rust-600 hover:cursor-pointer ml-[-40px]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                </svg>
            </button>
        </footer>
    );
}

export default ChatInputComponent;
