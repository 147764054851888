import { useState } from 'react';

const useFileFetcher = (): [(filePath: string , fileName: string) => Promise<void>, string | undefined, string | undefined, boolean, boolean, number | undefined] => {
    var [loading, setLoading] = useState<boolean>(false);
    var [error, setError] = useState<boolean>(false);
    var [errorCode, setErrorCode] = useState<number>();
    var [url, setUrl] = useState<string>();
    var [currentFileName, setCurrentFileName] = useState<string>();

    const getFile = async (filePath: string, fileName: string): Promise<void> => {
        setLoading(true);
        setError(false);
        setCurrentFileName(fileName);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/file?path=${filePath}&name=${fileName}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            });
            if (response.status !== 200) {
                setLoading(false);
                setErrorCode(response.status);
                setError(true);
                return;
            }

            const data = await response.blob();
            const file = new Blob([data], { type: 'application/pdf' });
            setUrl(URL.createObjectURL(file));

            setLoading(false);
        } catch (e) {
            console.error(e);
            setError(true);
            setLoading(false);
        }
    }

    return [getFile, currentFileName, url, loading, error, errorCode];
}

export default useFileFetcher;
