
import { useState, useEffect } from 'react';
import { Message } from '@/types/Message';
import useApi from 'hooks/use-api/useApi';

const useMessages = (): [Message[], (question: string) => void] => {
    const [askQuestion, data, loading, error, errorCode] = useApi();
    const [messages, setMessages] = useState<Message[]>([
        {
            message: 'Hey, hoe kan ik je helpen?',
            role: 'system'
        }
    ]);

    useEffect(() => {
        if (!loading) return;

        setMessages([
            ...messages,
            {
                message: '',
                loading: true,
                role: 'system'
            }
        ]);
    }, [loading]);

    useEffect(() => {
        if (!error) return;

        const newMessage: Message = {
            loading: false,
            message: "Oops, er is iets misgegaan. Probeer het later nog eens of controleer of je VPN aan staat als je vanuit huis werkt.",
            role: 'system'
        };

        setMessages((prevMessages) => [...prevMessages.slice(0, -1), newMessage]);
    }, [error]);

    useEffect(() => {
        if (loading || !data) return;

        const newMessage: Message = {
            message: data.message ?? "Something went wrong..",
            loading: false,
            role: 'system'
        };

        if (data.context != null) {
            newMessage.context = data.context;
        }

        setMessages((prevMessages) => [...prevMessages.slice(0, -1), newMessage]);
    }, [data]);

    const onSubmit = (question: string) => {
        askQuestion(question, messages);
        setMessages([
            ...messages,
            {
                message: question,
                role: 'user'
            }
        ]);
    }

    return [messages, onSubmit];
}

export default useMessages;
