import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { ChatInput, ChatWindow, Header } from './components';
import useMessages from 'hooks/use-messages/useMessages';
import useAuth from 'hooks/use-auth/useAuth';
import './App.css';

const App = () => {
  const [messages, onSubmit] = useMessages();
  const [handleLogout, account, userImage] = useAuth();

  return (
    <>
      <Header
        name={account?.name?.split('|')[0]}
        email={account?.username?.toLowerCase()}
        image={userImage}
        onLogout={handleLogout}
      />
      <UnauthenticatedTemplate>
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center space-y-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="h-24 w-24 text-gray-700 mx-auto"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>
            <h1 className="text-3xl font-bold text-gray-700">Not Authorized</h1>
            <p className="text-gray-600">Redirecting to login...</p>
          </div>
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <div className='flex flex-col mx-auto'>
          <ChatWindow messages={messages} userImage={userImage} />
          <ChatInput onSubmit={onSubmit} />
        </div>
      </AuthenticatedTemplate>
    </>

  );
}

export default App;
