import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from '@azure/msal-browser';
import { Buffer } from 'buffer';

const loginRequest = {
    scopes: ["User.Read"]
}

const useAuth = (): [() => void, AccountInfo, string | undefined] => {
    const [cancelled, setCancelled] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const [userImageUrl, setUserImageUrl] = useState<string>();
    const { instance, accounts, inProgress } = useMsal();

    const handleLogin = async () => {
        if (accounts.length > 0 || inProgress !== 'none') return;

        await instance.ssoSilent(loginRequest).catch(async () => {
            await instance.loginPopup(loginRequest).catch(() => {
                console.error("Login popup failed");
                setCancelled(true);
          });
        });
    };

    const handleLogout = async () => {
        await instance.logoutRedirect();
    };

    useEffect(() => {
        if (inProgress !== "none" || token !== undefined || accounts.length === 0 || cancelled) return;

        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setToken(response.accessToken);
            fetch("https://graph.microsoft.com/v1.0/me/photo/$value",
                {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${response.accessToken}`,
                        'Accept': 'image/png',
                    }
                })
                .then(res => res.arrayBuffer())
                .then(buffer => {
                    const avatar = Buffer.from(buffer).toString("base64");
                    setUserImageUrl("data:image/jpeg;base64, " + avatar);
                }).catch(error => {
                    console.warn("Profile image couldn't be fetched.", error);
                });
        }).catch(() => {
            console.error("Login failed");
            setCancelled(true);
        });
    }, [accounts, inProgress]);

    useEffect(() => {
        if (accounts.length > 0 || inProgress == "startup" || cancelled) return;

        handleLogin();
    }, [inProgress]);

    return [handleLogout, accounts[0], userImageUrl];
}

export default useAuth;
