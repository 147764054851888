import { useState } from 'react';
import { ChatResponse } from '@/types/ApiResponses';
import { useMsal } from '@azure/msal-react';
import { Message } from '@/types/Message';

const useApi = (): [(question: string, context: Message[]) => void, ChatResponse | undefined, boolean, boolean, number | undefined] => {
    var [loading, setLoading] = useState<boolean>(false);
    var [error, setError] = useState<boolean>(false);
    var [errorCode, setErrorCode] = useState<number>();
    var [data, setData] = useState<ChatResponse>();
    const { accounts } = useMsal();

    const askQuestion = async (question: string, context: Message[]): Promise<void> => {
        setLoading(true);
        setError(false);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    input: question,
                    context: context,
                    userEmail: accounts?.[0].username
                })
            });
            if (response.status !== 200) {
                setLoading(false);
                setErrorCode(response.status);
                setError(true);
                return;
            }

            const data = await response.json();
            setData(data);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setError(true);
            setLoading(false);
        }
    }

    return [askQuestion, data, loading, error, errorCode];
}

export default useApi;
