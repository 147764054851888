import { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react'

type HeaderProps = {
    name?: string;
    email?: string;
    image?: string;
    onLogout?: () => void;
}

const Header: React.FC<HeaderProps> = ({ name, email, image, onLogout }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!menuOpen) return;

        const handleClick = (event: MouseEvent) => {
            const menu = document.getElementById('user-section');
            if (menu && !menu.contains(event.target as Node)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [menuOpen]);

    return (
        <Disclosure as="nav" className="bg-black sticky z-50 top-0">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <img
                                className="h-[50px]"
                                src="/images/logo-white.png"
                                alt="Kruitbosch logo"
                            />
                        </div>
                        <div className="flex items-baseline space-x-4">
                            <span className='text-white rounded-md px-3 py-2 text-xl font-medium'>
                                KruitBuddy
                            </span>
                        </div>
                    </div>
                    <div id="user-section" className="ml-4 flex items-center md:ml-6 ">
                        <div className="relative ml-3">
                            <div className="flex items-center px-5">
                                <div className="flex-shrink-0">
                                    <button type="button" onClick={() => setMenuOpen(true)} className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" aria-expanded="false" aria-haspopup="true">
                                        <span className="sr-only">Open user menu</span>
                                        <img className="h-10 w-10 rounded-full" src={image ? image : 'images/default-user.jpg'} alt="User image" />
                                    </button>
                                </div>
                                <div className="ml-3 hidden md:block">
                                    {
                                        name && <div className="text-base font-medium leading-none text-white">{name}</div>
                                    }
                                    {
                                        email && <div className="text-sm font-medium leading-none text-gray-300">{email}</div>
                                    }
                                </div>
                            </div>
                            {
                                menuOpen && (
                                    <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button">
                                        <button onClick={onLogout} className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" id="user-menu-item-2">Logout</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Disclosure>
    );
}

export default Header;
