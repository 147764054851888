import React, { useState, useEffect } from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';

import { Message } from '@/types/Message';
import useFileFetcher from 'hooks/use-file-fetcher/useFileFetcher';
import ChatMessage from './ChatMessageComponent';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

type ChatWindowProps = {
    messages?: Message[];
    userImage?: string;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ messages, userImage }) => {
    const [pdfFile, setPdfFile] = useState<string>();
    const [showPdf, setShowPdf] = useState<boolean>(false);
    const [getFile, currentFileName, url, loading, error] = useFileFetcher();

    const spacer = (<div className="grow min-w-[20px] md:min-w-[100px]"></div>);

    const showPdfFile = async (path: string, name: string) => {
        if (name == null || name === undefined || loading || path === undefined) return;
        if (currentFileName === name) {
            setShowPdf(true);
            return;
        }

        await getFile(path, name);
    }

    useEffect(() => {
        if (url === undefined || url === null || url.length === 0 || error) return;
        setPdfFile(url);
        setShowPdf(true);
    }, [url]);

    const onPdfClose = () => {
        setShowPdf(false);
    }

    useEffect(() => {
        const chatWindow = document.querySelector('.chat-window');
        if (chatWindow === null) return;

        chatWindow.scrollTo({
            top: chatWindow.scrollHeight,
            behavior: 'smooth',
        });
    }, [messages]);

    return (
        <div className='flex flex-grow '>
            <div className='flex-grow chat-window'>
                <div className="bg-stone-100 p-4 pb-8 sm:px-6 lg:px-8 flex flex-col">
                    {messages && messages.map((message, index) => (
                        <ChatMessage key={index} message={message} userImage={userImage} showPdfFile={showPdfFile} />
                    ))}
                </div>
            </div>
            <div className={`flex-grow min-w-[50%] max-w-[500px] h-screen pb-24 border border-gray ${showPdf ? '' : 'hidden'} `} >
                <button className='mt-2 mb-2 -ml-2 text-gray-500 border border-gray-300 rounded-full bg-stone-100 shadow-md flex items-center justify-center h-6 w-6' onClick={onPdfClose}>
                    <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="m9 18 6-6-6-6" />
                    </svg>
                </button>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer defaultScale={SpecialZoomLevel.PageWidth} fileUrl={pdfFile ?? 'noFile'} />
                </Worker>
            </div>
        </div>
    );
}

export default ChatWindow;