import React from 'react';
import ReactMarkdown from 'react-markdown'
import { Message } from '@/types/Message';

type ChatMessageComponentProps = {
    message: Message;
    userImage?: string;
    showPdfFile: (path: string, name: string) => void
}

const ChatMessage: React.FC<ChatMessageComponentProps> = ({ message, userImage, showPdfFile }) => {
    const spacer = (<div className="grow min-w-[20px] md:min-w-[100px]"></div>);

    const getChatImage = (role: string) => {
        if (role === 'system') {
            return '/images/system-user.png';
        }

        return userImage ? userImage : 'images/default-user.jpg';
    }

    return (
        <div className="w-full flex pb-4 flex-row">
            {message.role === 'user' && spacer}
            <img className='rounded-full h-[2em] mr-[1em]' src={getChatImage(message.role)} alt="Chat message user image" />
            <div className={`p-4 rounded-md drop-shadow-md inline-block border-solid border-2 border-grey-500 ${message.role === 'user' ? 'bg-knight-100 text-white' : 'bg-white'}`}>
                {
                    message.loading &&
                    <div className="dot-typing"></div>
                }
                <ReactMarkdown>{message.message}</ReactMarkdown>
                {message.role === 'system' && message.context && (
                    <>
                        <hr className='mt-2' />
                        <div className='mt-3 flex'>
                            {
                                message.context.map((context, index) => (
                                    <div className='mr-2' key={index}>
                                        {
                                            context.filePath.startsWith('https://kruitbosch.sharepoint.com') &&
                                            <a target="_blank" href={context.filePath} aria-label={`Open ${context.fileName} file`} className='inline-flex items-center px-3 py-1 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'>
                                                {context.fileName}
                                            </a>
                                        }
                                        {
                                            !context.filePath.startsWith('https://kruitbosch.sharepoint.com') &&
                                            <a href={`${process.env.REACT_APP_API_HOST}/file?path=${context.filePath}&name=${context.fileName}`} aria-label={`Open ${context.fileName} file`} className='inline-flex items-center px-3 py-1 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'>
                                                {context.fileName}
                                            </a>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )}
            </div>
            {message.role === 'system' && spacer}
        </div>
    );
}

export default ChatMessage;